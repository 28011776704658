import TodoList from "./components/TodoList";
import Header from "./components/Header";


// Header Komponente, zeigt Todos erledigt: 2 von 5 an
// Todolist Komponente: liest state aus und zeigt alle Todos an etc.
// Redux toolkit verwenden

export function App() {
    return (
        <div>
            <Header/>
            <TodoList/>
        </div>);
}

/*const App = (): React.JSX.Element => {
    const [todos, setTodos] = useState<ITodo[]>([{id: 1, name: 'do homework', isChecked: false}]);
    const [inputValue, setInputValue] = useState<string>('');

    const handleAddTodo = () => {
        if (inputValue.trim()) {
            const newTodo: ITodo = {id: Date.now(), name: inputValue, isChecked: false}
            setTodos([...todos, newTodo])
            setInputValue('')
        }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleAddTodo()
        }
    }

    const handleDeleteTodo = (id: number) => {
        setTodos(todos.filter(todo => todo.id !== id))
    }

    const handleEditTodo = (id: number, newname: string) => {
        setTodos(todos.map(todo => (todo.id === id ? {...todo, name: newname} : todo)));
    }

    const handleCheckTodo = (id: number) => {
        setTodos(todos.map(todo => (todo.id === id ? {...todo, name: todo.name, isChecked: !todo.isChecked} : todo)))
    }


    return (
        <div className="App">
            <header className="App-header">
                <h1>TODO`s</h1>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="ADD TODO"
                        value={inputValue}
                        onKeyUp={onKeyDown}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </div>
                <ul id="todo-list">
                    {todos.map(todo => (
                        <Todo
                            key={todo.id}
                            todo={todo}
                            onDelete={handleDeleteTodo}
                            onEdit={handleEditTodo}
                            onCheck={handleCheckTodo}
                        />
                    ))}
                </ul>
            </header>
        </div>
    );
};

export default App;*/
