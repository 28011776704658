import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from "../interfaces/IStoreState";
import { checkTodo, deleteTodo, editTodo } from "../redux/todoSlice";
import React from "react";
import Todo from "./Todo";

import styles from "../components/todolist.module.css";



export default function TodoList() {
    const todos = useSelector((state: IStoreState) => state.todos);
    const dispatch = useDispatch();

    function handleDeleteTodo(id: number) {
        dispatch(deleteTodo({ id }));
    }

    function handleEditTodo(id: number, newName: string) {
        dispatch(editTodo({ id, newName }));
    }

    function handleCheckTodo(id: number) {
        dispatch(checkTodo({ id }));
    }

    return (
        <div className={styles.app}>
            <div className={styles.todolist}>
                <ul className={styles.ul} id="todo-list">
                    {Array.isArray(todos) && todos.map(todo => (
                        <Todo
                            key={todo.id}
                            todo={todo}
                            onDelete={handleDeleteTodo}
                            onEdit={handleEditTodo}
                            onCheck={handleCheckTodo}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
}