import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ITodo } from "../interfaces/ITodo";
import { addTodo, setInputValue } from "../redux/todoSlice";
import { RootState } from "../redux/store";

import styles from "./header.module.css";


export default function Header() {
    const dispatch = useDispatch();
    const inputValue = useSelector((state: RootState) => state.inputValue);
    const todoList = useSelector((state: RootState) => state.todos);


    function handleAddTodo() {
        const todo: ITodo = {
            id: Date.now(),
            name: inputValue,
            isChecked: false,
            isEditMode: false,
            editInput: inputValue
        };

        if (todo.name) {
            dispatch(addTodo(todo));
            dispatch(setInputValue('')
            );
        }
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setInputValue(e.target.value));
    }

    function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            handleAddTodo();
        }
    }

    function countTodosChecked() {
        const todosChecked = todoList.filter(todos => todos.isChecked);
        return todosChecked.length;
    }

    function countTodos() {
        return todoList.length;
    }


    return (
        <div className="App">
            <header className={styles.Appheader}>
                <h1 className={styles.h1}>TODO`s | Done: {countTodosChecked()}/{countTodos()}</h1>
                <div className={styles.inputcontainer}>
                    <input
                        className={styles.inputcontainer}
                        type="text"
                        placeholder="ADD TODO"
                        value={inputValue}
                        onKeyUp={handleKeyUp}
                        onChange={handleInputChange}
                    />
                    <button onClick={handleAddTodo}>ADD</button>
                </div>
            </header>
        </div>
    );
}