import { ITodo } from "../interfaces/ITodo";
import React, { useState } from "react";

import styles from "../components/todo.module.css";


export interface ITodoProps {
    todo: ITodo;
    onDelete: (id: number) => void;
    onEdit: (id: number, newName: string) => void;
    onCheck: (id: number) => void;
}


function Todo(props: ITodoProps): React.JSX.Element {
    const { todo, onDelete, onEdit, onCheck } = props;

    const [editInputFieldText, setEditInputFieldText] = useState<string>('');
    const [showEditInput, setShowEditInput] = useState<boolean>(false);
    // showeditinput usestate, local css css modules, container erstellen

    function openEditMode() {
        setEditInputFieldText(todo.name);
        setShowEditInput(true);
    }

    function onChange(text:string) {
        setEditInputFieldText(text);
    }

    function closeEditMode() {
        setShowEditInput(false);
    }

    return (
        <li>
            {!showEditInput &&
                <span className={styles.todoname} onClick={openEditMode}
                      style={{ textDecoration: todo.isChecked ? 'line-through' : 'underline' }}>{todo.name}</span>}

            {showEditInput &&
                <>
                    <input style={{ textDecoration: todo.isChecked ? 'line-through' : 'none' }}
                           type="text"
                           className={styles.ul}
                           value={editInputFieldText}
                           onChange={(e) => onChange(e.target.value)}/>
                    <button onClick={closeEditMode} className={styles.cancelBtn}>cancel</button>
                    <button onClick={() => {
                        onEdit(todo.id, editInputFieldText);
                        closeEditMode();
                    }} className={styles.saveBtn}>save</button>
                </>
            }

            <button onClick={() => onDelete(todo.id)} className={styles.deleteBtn}>delete</button>

            <input
                type="checkbox"
                checked={todo.isChecked}
                onChange={() => onCheck(todo.id)}
                className={styles.checkBox}
            />
        </li>
    );
}


export default Todo;
