import { createSlice } from '@reduxjs/toolkit';


export const todoSlice = createSlice({
    name: 'todos',
    initialState: {
        todos: [
            { id: 1, name: 'do homework', isChecked: false }
        ],
        inputValue: '',
    },
    reducers: {
        addTodo: (state, action) => {
            const todo = {
                id: action.payload.id,
                name: action.payload.name,
                isChecked: action.payload.isChecked,
            };
            state.todos.push(todo);
        },
        deleteTodo: (state, action) => {
            state.todos = state.todos.filter(todo => todo.id !== action.payload.id);
        },
        editTodo: (state, action) => {
            const { id, newName } = action.payload;
            const todo = state.todos.find(todo => todo.id === id);
            if (todo) {
                todo.name = newName;
            }
        },
        checkTodo: (state, action) => {
            const { id } = action.payload;
            const todo = state.todos.find(todo => todo.id === id);
            if (todo) {
                todo.isChecked = !todo.isChecked;
            }
        },
        setInputValue: (state, action) => {
            state.inputValue = action.payload;
        },
    },
});

export const {
    addTodo,
    deleteTodo,
    editTodo,
    checkTodo,
    setInputValue,
} = todoSlice.actions;

export default todoSlice.reducer;
